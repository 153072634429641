<template>
  <div class="container">
    <Title title="Contact Us" />
    <div class="content">
      <div class="location-macgregor">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3537.050002799782!2d153.06886211486773!3d-27.560958582852777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b9144d2d961ff01%3A0xf42db8fff873d9ac!2sCBJ%20Health%20Club!5e0!3m2!1sen!2sau!4v1642151210687!5m2!1sen!2sau"
          style="border: 2px solid #009cdf; border-radius: 1em"
          allowfullscreen=""
          loading="lazy"
          class="map"
        ></iframe>
        <div class="contact-details">
          <h2>Address</h2>
          <p>CBJ The Health Club, 568 Kessels Rd, MacGregor QLD 4109</p>
          <h2>Fax</h2>
          <p>3349 1576</p>
          <h2>Email</h2>
          <p>
            <a href="mailto:reception.prohealthep@gmail.com"
              >reception.prohealthep@gmail.com</a
            >
          </p>
          <h2>Mobile</h2>
          <p>0490 192 846</p>
        </div>
      </div>
      <hr class="hr" />
      <div class="location-macgregor">
        <div class="contact-details">
          <h2>Address</h2>
          <p>
            Lifestyle Health Club, 344 Redbank Plains Rd, Bellbird Park QLD 4300
          </p>
          <h2>Fax</h2>
          <p>3814 2016</p>
          <h2>Email</h2>
          <p>
            <a href="mailto:reception.prohealthep@gmail.com"
              >reception.prohealthep@gmail.com</a
            >
          </p>
          <h2>Mobile</h2>
          <p>0490 192 846</p>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3534.375062120021!2d152.87037091486977!3d-27.643865382818202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b914b00b9df2fdb%3A0xc51945481793168!2sLifestyle%20Health%20Clubs!5e0!3m2!1sen!2sau!4v1642151975654!5m2!1sen!2sau"
          style="border: 2px solid #009cdf; border-radius: 1em"
          allowfullscreen=""
          loading="lazy"
          class="map"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

.content {
  margin: 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
}

.location-macgregor {
  display: grid;
  @media only screen and (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 3em;
}

.hr {
  margin: 3em 0em;
}

h2 {
  color: #009cdf;
}

a {
  color: black;
}

.map {
  height: 50vh;
  width: 100%;
}
</style>
