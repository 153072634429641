<template>
  <div class="container">
    <Title title="Referrals" />
    <div class="content">
      <h2>Medicare Referrals</h2>
      <p>
        Under the Chronic Disease Management Program, Medicare rebates are
        available for patients with chronic and complex care needs on referral
        from their GP. Additional rebates for patients diagnosed with Type 2
        Diabetes under the Type 2 Diabetes Group Services Initiative and for
        patients identifying as Aboriginal Torres Strait Islander are also
        accessible.
      </p>
      <p>
        To find out more about these initiatives, and download the required
        referral paperwork visit
        <a
          href="hhttps://www1.health.gov.au/internet/main/publishing.nsf/Content/mbsprimarycare-chronicdiseasemanagement"
          class="link"
        >
          health.gov.au
        </a>
      </p>
      <h2>DVA Referrals</h2>
      <p>
        Entitled Department of Veterans' Affairs (DVA) clients may be referred
        to an AEP for clinically necessary Exercise Physiology treatment by
        their General Practitioner on a valid D904 referral form.
      </p>
      <h2>WorkCover and Insurance Referrals</h2>
      <p>
        AEPs provide functional exercise for the purpose of returning an injured
        worker to suitable work activities; through tailored exercise
        prescription suitable to the worker's injury or work demands, and/or
        specific work activities such as increasing movement capacity.
      </p>
      <h2>NDIS</h2>
      <p>
        All exercise physiology treatment costs will be funded by the NDIS when
        the NDIA plan has been approved under the Health and Wellbeing support
        category; and/or Therapeutic Supports; and/or Improved Daily Living;
        and/or Core Supports.
      </p>
      <h2>Active Ageing</h2>
      <p>
        Exercise Right for Active Ageing is a national program designed for
        older Australians over 65 (or Indigenous Australians who are over 55)
        funded by the Australian Government under the Move it AUS - Better
        Ageing Grants Program.
      </p>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
}

h2 {
  color: #009cdf;
}

p {
  line-height: 2em;
}

.link {
  color: black;
}
</style>
