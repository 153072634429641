<template>
  <div class="container">
    <Title title="About Us" />
    <div class="content">
      <div>
        <h2>What is Exercise Physiology?</h2>
        <p>
          Our Accredited Exercise Physiologists (AEP) are allied health
          professionals who specialise in clinical exercise interventions for a
          broad range of pathological populations, including those at risk of
          developing, or have existing medical conditions and injuries. Exercise
          physiology interventions aim to prevent or manage chronic disease
          and/or injury to assist in restoring optimal physical function, health
          and well-being. Our exercise physiology interventions are
          exercise-based and include health and physical activity education,
          advice and support and lifestyle modification with particular focus on
          achieving behavioural change.
        </p>

        <h2>
          Who can benefit from seeing an Accredited Exercise Physiologist?
        </h2>
        <p>
          Our exercise physiologists (AEPS) have great knowledge across a broad
          range of chronic conditions and injuries including but not limited to:
        </p>
        <ul>
          <li>
            <b>Cardiopulmonary conditions:</b> high blood pressure, heart
            disease, peripheral vascular disease, heart failure, asthma, lung
            disease
          </li>
          <li>
            <b>Metabolic conditions:</b> obesity, high cholesterol, diabetes,
            pre-diabetes
          </li>
          <li>
            <b>Musculoskeletal:</b> arthritis, osteoporosis, joint pain, low
            back pain
          </li>
          <li>
            <b>Neurological/neuromuscular conditions:</b> stroke, spinal cord
            injury, brain injury, Parkinson's disease, MS, cerebral palsy
          </li>
        </ul>
      </div>
      <img
        src="@/assets/pexels-photo-3927387.jpeg"
        alt="Exercise Equipment"
        id="image"
      />
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 2rem;
}

h2 {
  color: #009cdf;
}

p {
  line-height: 2em;
}

#image {
  width: 40vw;
  display: none;
  @media screen and (min-width: 1280px) {
    display: block;
  }
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>
